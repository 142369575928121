import React from "react"
import Layout from "../components/layout/Layout"
import BannerSection from "../components/layout/components/banner-section/BannerSection"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    strapiHome {
      message
    }
  }
`

export default function Home({ data }) {
  console.log(data, "data here")
  // git push heroku HEAD:main
  const message = data.strapiHome.message
  return (
    <Layout>
      <BannerSection message={message} />
    </Layout>
  )
}
