// extracted by mini-css-extract-plugin
export var blue = "ProgressSection-module--blue--RHhDT";
export var dot = "ProgressSection-module--dot--oq1lL";
export var green = "ProgressSection-module--green--ZsvCo";
export var heading = "ProgressSection-module--heading--JHgt2";
export var pink = "ProgressSection-module--pink--AjeRQ";
export var point = "ProgressSection-module--point--w5L0G";
export var pointsWrapper = "ProgressSection-module--pointsWrapper--pjE8Y";
export var progressFooter = "ProgressSection-module--progressFooter--D-mpD";
export var progressWrapper = "ProgressSection-module--progressWrapper--zmPIC";
export var red = "ProgressSection-module--red--riMuz";
export var singlePointContainer = "ProgressSection-module--singlePointContainer--2yV6m";
export var text = "ProgressSection-module--text--BoURy";
export var title = "ProgressSection-module--title--RPUOx";