import React from "react"
import * as Style from "./ProgressSection.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown"

const query = graphql`
  query {
    allStrapiProgress {
      nodes {
        main_heading {
          data {
            main_heading
          }
        }
        porgress_comp {
          id
          color
          info {
            data {
              info
            }
          }
          title {
            data {
              title
            }
          }
        }
        footer_info {
          data {
            footer_info
          }
        }
      }
    }
  }
`

export const ProgressSection = () => {
  const data = useStaticQuery(query)
  const progressData = data.allStrapiProgress.nodes[0]

  return (
    <div className={Style.progressWrapper}>
      <div className={Style.heading}>
        <Markdown children={progressData.main_heading.data.main_heading} />
      </div>
      <div className={Style.pointsWrapper}>
        {progressData.porgress_comp.map(item => {
          return (
            <div
              key={item.id}
              className={`${Style.singlePointContainer} ${Style[item.color]}`}
            >
              <div className={`${Style.point}`}>
                <div className={Style.dot} />
                <div className={Style.title}>
                  <Markdown children={item.title.data.title} />
                </div>
                <div className={Style.text}>
                  <Markdown children={item.info.data.info} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className={Style.progressFooter}>
        <Markdown children={progressData.footer_info.data.footer_info} />
      </div>
    </div>
  )
}
