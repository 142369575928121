// extracted by mini-css-extract-plugin
export var bannerInfo = "BannerSection-module--bannerInfo--aGYBY";
export var bannerSection = "BannerSection-module--bannerSection--GwQik";
export var btnOne = "BannerSection-module--btnOne--7cnLE";
export var btnTwo = "BannerSection-module--btnTwo--N3PPX";
export var ctaWrapper = "BannerSection-module--ctaWrapper--u4fic";
export var endPhaseOneText = "BannerSection-module--endPhaseOneText--J-eLi";
export var heading = "BannerSection-module--heading--UKQ+a";
export var infoBox = "BannerSection-module--infoBox--TYuVC";
export var infoBoxTwo = "BannerSection-module--infoBoxTwo--eWZdj";
export var infoBoxTwoAlt = "BannerSection-module--infoBoxTwoAlt--kfOnw";
export var message = "BannerSection-module--message--UdpXr";
export var modalPosterImgWrapper = "BannerSection-module--modalPosterImgWrapper--9MCdb";
export var posterModal = "BannerSection-module--posterModal--a5Q8x";
export var progressSectionContainer = "BannerSection-module--progressSectionContainer--YERFv";
export var slideDown = "BannerSection-module--slide-down--DRBDq";
export var slideLeft = "BannerSection-module--slide-left--kwREH";
export var slideRight = "BannerSection-module--slide-right--cvdTH";
export var video = "BannerSection-module--video--KdISw";
export var videoWrapper = "BannerSection-module--videoWrapper--S6NSt";
export var warrning = "BannerSection-module--warrning--fb5aN";